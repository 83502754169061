import Link from "next/link";
import SvgJsx from "../templateux/svg/svg-jsx";
import HeaderRow from "./headerrow";

export default function SubHeader({
    headertitle
    ,action_condition
    ,action_url
    ,action_text
    ,action_extra=<></>
}) {


    return <>
                <HeaderRow
                    headertitle={headertitle}
                    action_condition={action_condition}
                    action={(<>
                            <div
                                    className="flex-0 "
                                >
                                <Link
                                    href={action_url}
                                    className={`flex items-center content-center border px-1.5 py-0.5 rounded-md hover:shadow-sm 
                                            text-gray-500 border-gray-400 hover:border-gray-200 hover:text-gray-200
                                                `}>

                                    {/* <SvgJsx 
                                        type='fill'
                                        icon='pencil-sm'
                                        className={` h-3 w-3 flex-0 mr-1`}
                                        title="Premium Feature"
                                    /> */}
                                    <span className='flex-0 text-2xs font-bold'>
                                        {action_text}
                                    </span>

                                </Link>
                            </div>
                            {action_extra}
                            </>)}
                    
                />
    </>;

}